import { useContext } from "react";
import FillupCard from "../Components/Profile/FillupCard";
import { useAuth0 } from "@auth0/auth0-react";
import FillupVehicleButtons from "../Components/LookAndFeel/FillUpVehicleButtons";
import ActiveVehicleList from "./../Components/Profile/ActiveVehicleList";
import AppContext from "../auth/AppContext";
import { ToastContainer, toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";
import { getFillups } from "../API/FillupApi";
import LoadingInApp from "../Loading/LoadingInApp";
import HorizontalLine from "../Components/LookAndFeel/HorizontalLine";
import Variables from "../Utils/Variables";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";

function Profile() {
  const [searchParams] = useSearchParams();
  const [isFromFillup, setIsFromFillup] = useState(false);

  const { user } = useAuth0();
  const { token, groups } = useContext(AppContext);

  // Get the last 4 fill ups to display on page
  const { data: fillups, isLoading } = useQuery({
    queryKey: [Variables.FILLUPS, (user, 4, token)],
    queryFn: () => getFillups(user, 4, token),
  });

  // See if we got here from the fillup page
  useEffect(() => {
    if (searchParams.get("isFillup") === "true") {
      setIsFromFillup(true);
    }
  }, []);

  // If we got here from the fillup page, alert it was successful
  useEffect(() => {
    if (isFromFillup) {
      toast.success(`Fill up successfully added!`, {
        theme: "light",
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [isFromFillup]);

  return (
    <>
      Name: {user.name}
      <br></br>
      Email: {user.email}
      <br></br>
      {groups?.length > 0 && (
        <>
          Groups:
          {groups?.map((group) => {
            return ` ${group.name} `;
          })}
        </>
      )}
      <div className="text-center pt-3">
        <FillupVehicleButtons />
      </div>
      <p></p>
      <ActiveVehicleList />
      <HorizontalLine />
      <h4 className="">Recent Fillups</h4>
      <div className="row" key="fillupRow">
        {isLoading ? (
          <LoadingInApp />
        ) : (
          fillups &&
          fillups?.map((fillup) => {
            return <FillupCard fillup={fillup} />;
          })
        )}
      </div>
      <ToastContainer />
    </>
  );
}

export default Profile;
